/**
 * Created by Octavian Ta on 14/09/2020.
 */

define(['$window', 'ePopup', 'app', 'simpleSlider', 'cardScroller', 'siteObj', 'accessibilityFocusHelper'], ($window, ePopup, app, simpleSlider, cardScroller, siteObj, accessibilityFocusHelper) => {

  const ugcProductCarousel = () => {
    const component = {};

    const _config = {
      ugcProductCarousel: '[data-js-element="ugcProductCarousel_popupCarousel"]',
      ugcPopup: '[data-js-element="ugcProductCarousel_popup"]',
      thumbnailSlider: '[data-js-element="ugcProductCarousel_thumbnail"]',
      thumbnailContainer: '[data-js-element="ugcProductCarousel_thumbnail_wrapper"]',
      popupImage: '[data-json-element="ugcProductCarousel_popupAssets"]',
      imageDataAttr: 'data-ugc-standard',
      imageMedDataAttr: 'data-ugc-medium',
      imageAvatarDataAttr: 'data-ugc-avatar',
      imageCaptionDataAttr: 'data-ugc-caption',
      imageUsernameDataAttr: 'data-ugc-username',
      imagePostDataAttr: 'data-user-post',
      imageThumbnailDataAttr: 'data-ugc-thumbnail',
      popupClassName: 'ugcProductCarousel_popupOverlay',
      popupTitle: '.ugcProductCarousel_popupContentTitle',
      popupHiddenTitle: '.ugcProductCarousel_popupHiddenTitle',
      thumbnailData: 'data-ugc-thumbnail',
      popupCarouselContainer: 'ugcProductCarouselPopup',
      popupCarouselContent: 'ugcProductCarousel_popupCarouselContent'
    };

    /**
     * init
     */
    const _init = (element) => {
      component.popupCarousel(element);
      component.thumbnailCarousel(element);
      component.thumbnailHandler();

      return component;
    };

    /**
     * popupCarousel
     */
    const _popupCarousel = (el) => {
      component.ugcProductCarousel = el.querySelector(component.config.ugcProductCarousel);
      component.popups = el.querySelector(component.config.ugcPopup);
      component.thumbnailSlider = el.querySelector(component.config.thumbnailSlider);
      component.thumbnailContainer = el.querySelector(component.config.thumbnailContainer);
      component.popupImages = el.querySelectorAll(component.config.popupImage);
      component.popupTitle = el.querySelector(component.config.popupTitle);
      component.popupCarouselContainer = el.getElementsByClassName(component.config.popupCarouselContainer)[0];

      component.popupOptions = {
        wrapper: '[data-js-element="ugcProductCarousel_popupCarousel"]',
        prev: '[data-js-element="ugcProductCarousel_popupButton-prev"]',
        next: '[data-js-element="ugcProductCarousel_popupButton-next"]',
        buttons: 'ugcProductCarousel_popupButton',
        slide: '.ugcProductCarousel_popupCarouselSlider',
        navBullets: 'ugcProductCarousel_popupNavBullets',
        navBullet: 'ugcProductCarousel_popupNavBullet',
        activeBulletClass: 'ugcProductCarousel_popupNavBullet-active',
        show: 'show',
        loop: true,
        switchKey: true
      };

      component.popupButtons = Array.from(el.getElementsByClassName(component.popupOptions.buttons));
      component.contentElements = Array.from(el.getElementsByClassName(component.config.popupCarouselContent));

      component.popUpbulletElements = Array.from(el.getElementsByClassName(component.popupOptions.navBullet));
      component.ugcSlider = new simpleSlider(component.popups, component.popupOptions);
    };

    /**
     * thumbnailCarousel
     */
    const _thumbnailCarousel = (el) => {
      component.ugcThumbnailCarousel = new cardScroller();
      component.ugcThumbnailCarousel.init(el);
    };

    /**
     * thumbnailHandler
     */
    const _thumbnailHandler = () => {
      component.thumbnailContainer.addEventListener('click', (e) => {
        const thumbnail = e.target;
        component.initThumbnail(thumbnail);
      });
    };

    /**
     *  initThumbnail
     * @param thumbnail
     */
    const _initThumbnail = (thumbnail) => {
      component.currentThumbnail  = app.element.getAttribute(component.config.imageThumbnailDataAttr, thumbnail);
      component.currentThumbnail = Number(component.currentThumbnail);
      component.initPopup(component.currentThumbnail);
      component.ugcSlider.total = component.popupImages.length;
    };

    /**
     * nextImageHandler
     */
    const _nextImageHandler = (el) => {

      el.addEventListener('click', () => {
        component.slideIndex();
      });

      el.addEventListener('touchend', () => {
        component.slideIndex();
      });

      el.addEventListener('keydown', () => {
        component.slideIndex();
      });
    };

    /**
     * slideIndex
     */
    const _slideIndex = () => {
      if (component.currentThumbnail !== component.ugcSlider.current) {
        component.highlightPopupNavBullets(component.ugcSlider.current, component.currentThumbnail);
        component.currentThumbnail = component.ugcSlider.current;
        component.renderNewSlide(component.currentThumbnail);
        component.focusSlide();
      }
    };

    /**
     * initPopup
     * @param sliderNumber
     * @returns {string}
     */
    const _initPopup = (sliderNumber) => {
      component.nextImageHandler($window);
      component.highlightPopupNavBullets(sliderNumber);
      document.getElementsByClassName(component.popupOptions.navBullets)[0].classList.add(component.popupOptions.show);
      component.renderNewSlide(sliderNumber);
      component.popupCarouselContainer.classList.add(component.popupOptions.show);
      component.popupButtons.forEach((button) => {
        button.classList.add(component.popupOptions.show)
      });
      component.ePop = new ePopup(component.popups, component.config.popupClassName, true);
      component.ugcSlider.current = sliderNumber;
      component.focusSlide();
    };

    const _focusSlide = () => {
      component.popupTitle.innerText
        ? accessibilityFocusHelper.focus(component.popupTitle)
        : accessibilityFocusHelper.focus(component.ugcProductCarousel.querySelector(component.config.popupHiddenTitle));
    };

    const _highlightPopupNavBullets = (sliderNumber, previousBullet) => {
      component.popUpbulletElements.forEach((navBullet) => {
        navBullet.classList.remove(component.popupOptions.activeBulletClass);
      });
      let bulletElement = component.popUpbulletElements[sliderNumber];
      bulletElement.classList.add(component.popupOptions.activeBulletClass);
      if(previousBullet) {
        let prevBullet = component.popUpbulletElements[previousBullet];
        prevBullet.classList.remove(component.popupOptions.activeBulletClass);
      }
    };

    /**
     * renderNewSlide
     * @param thumbnailNumber
     * @returns {*|string}
     */
    const _renderNewSlide = (thumbnailNumber) => {
      component.currentThumbnail = thumbnailNumber;
      component.standardImage = component.popupImages[thumbnailNumber];
      component.sliderImageUrl = app.element.getAttribute(component.config.imageDataAttr, component.standardImage);
      component.sliderMediumImageUrl = app.element.getAttribute(component.config.imageMedDataAttr, component.standardImage);
      component.popupAvatar = app.element.getAttribute(component.config.imageAvatarDataAttr, component.standardImage);
      component.popupCaption = app.element.getAttribute(component.config.imageCaptionDataAttr, component.standardImage);
      component.popupUsername = app.element.getAttribute(component.config.imageUsernameDataAttr, component.standardImage);
      component.popupUserSuffix = component.ugcProductCarousel.getAttribute(component.config.imagePostDataAttr);
      component.contentElements.forEach((content) => {
        content.style.display = "none"
      });
      component.contentElements[thumbnailNumber].removeAttribute("style");
    };

    component.config = _config;
    component.init = _init;
    component.popupCarousel = _popupCarousel;
    component.thumbnailCarousel = _thumbnailCarousel;
    component.thumbnailHandler = _thumbnailHandler;
    component.initThumbnail = _initThumbnail;
    component.highlightPopupNavBullets = _highlightPopupNavBullets
    component.nextImageHandler = _nextImageHandler;
    component.slideIndex = _slideIndex;
    component.initPopup = _initPopup;
    component.focusSlide = _focusSlide;
    component.renderNewSlide = _renderNewSlide;

    return component;
  };

  return ugcProductCarousel;
});
